import MaterialTable from "@shared/components/MaterialTable/MaterialTable";
import { createStyles, Grid, makeStyles, Typography } from "@material-ui/core";
import { FC, useMemo } from "react";
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import {
  deletePayoutHistory,
  downloadPayoutHistory,
  downloadXmlCarRent,
  downloadXmlContract,
  getPayoutsHistory,
} from "@shared/services/payout/payout.service";
import ErrorFallback from "../../../../../error";
import Loading from "@shared/components/Loading/Loading";
import { Add, Delete, DirectionsCar, GetApp, Payment, SaveAlt } from "@material-ui/icons";
import type { PayoutHistoryDto } from "@shared/services/payout/dtos/payoutHistory";
import dayjs from "dayjs";
import type { Column, Localization } from "@material-table/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    heading: {
      marginBottom: theme.spacing(4),
    },
    filters: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    summaryCards: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    periodTable: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    redIcon: {
      color: "red",
    },
  })
);
const PayoutsList: FC = () => {
  const classes = useStyles();

  const {
    data: payoutsData,
    isLoading,
    error,
    refetch,
  } = useQuery(["employees"], getPayoutsHistory);

  const columns: Column<PayoutHistoryDto>[] = [
    {
      title: "Период до",
      field: "periodTo",
      render: (rowData) => dayjs(rowData.periodTo).format("DD.MM.YYYY"),
    },
    { title: "Вкупно", field: "total" },
    { title: "Фиксна плата", field: "fullBenefits" },
    { title: "Верна", field: "verna" },
    { title: "Закуп возило", field: "carRent" },
    { title: "Договор на дело", field: "contract" },
  ];

  const localization: Localization = {
    header: {
      actions: "Симни плата", // This sets the title for the actions column
    },
  };
  const handleDownload = async (id: number) => {
    try {
      await downloadPayoutHistory(id);
    } catch (error) {
      console.log(error);
    }
  };
  const handleXmlCarRent = async (id: number) => {
    try {
      await downloadXmlCarRent(id);
    } catch (error) {
      console.log(error);
    }
  };
  const handleXmlContract = async (id: number) => {
    try {
      await downloadXmlContract(id);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = (id: number) => {
    if (window.confirm("Дали сте сигурни дека сакате да ја избришете оваа исплата?")) {
      deletePayoutHistory(id).then(() => refetch());
    }
  };

  if (isLoading) return <Loading />;

  console.log(payoutsData);

  return (
    <Grid container>
      <Grid container className={classes.heading}>
        <Grid item>
          <Typography variant="h5">Историја на исплати</Typography>
        </Grid>
      </Grid>
      <MaterialTable<PayoutHistoryDto>
        title={"Историја на исплати"}
        columns={columns}
        data={payoutsData ?? []}
        actions={[
          {
            icon: SaveAlt,
            tooltip: "Excel Плата",
            onClick: (event, rowData) => {
              const ph = rowData as PayoutHistoryDto;
              handleDownload(ph.id);
            },
          },
          {
            icon: DirectionsCar,
            tooltip: "XML Закуп",
            onClick: (event, rowData) => {
              const ph = rowData as PayoutHistoryDto;
              downloadXmlCarRent(ph.id);
            },
          },
          {
            icon: Payment,
            tooltip: "XML Договор",
            onClick: (event, rowData) => {
              const ph = rowData as PayoutHistoryDto;
              downloadXmlContract(ph.id);
            },
          },
          {
            icon: () => <Delete className={classes.redIcon} />,
            tooltip: "Избриши",
            onClick: (event, rowData) => {
              const ph = rowData as PayoutHistoryDto;
              handleDelete(ph.id);
            },
          },
        ]}
        options={{
          addRowPosition: "first",
          actionsColumnIndex: -1,
          tableLayout: "auto",
          padding: "dense",
          pageSize: 10,
          pageSizeOptions: [10, 20, 30],
        }}
        localization={localization}
      ></MaterialTable>
    </Grid>
  );
};

export default PayoutsList;

