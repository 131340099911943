import PaycheckHeader from "./components/PaycheckHeader";
import PayrollDaysSummary from "./components/PayrollDaysSummary";
import PayrollOrders from "./components/PayrollOrders";
import PayrollSelection from "./components/PayrollSelection";
import { Button, Grid, Paper, createStyles, makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { IPayrollDto } from "@shared/services/payroll/dtos/old/payrollDto";
import {
  exportEmployeePayrollExcel,
  getEmployeePayrollsForEmployee,
  getPayrolls,
} from "@shared/services/payroll/payroll-old.service";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getEmployeeByUserId } from "@shared/services/employees/employees.service";

import dayjs from "dayjs";
import { FC, useState, useEffect } from "react";
import { IEmployeeBaseDto } from "@shared/services/employees/dtos/employeeBaseDto";
import { getCurrentUser } from "@shared/services/auth/auth.service";
import { IPeriodDateFilter } from "@App/modules/shared/components/PeriodToFilter";
import { getPayrollPeriodSum } from "@shared/services/payroll/payroll.service";
import { IPayrollPeriodFiltersDto } from "@shared/services/payroll/dtos/request/payrollPeriodFiltersDto";

const useStyles = makeStyles((theme) =>
  createStyles({
    report: {
      display: "flex",
      justifyContent: "center",
      padding: theme.spacing(2),
    },
  })
);

const Paycheck: FC = () => {
  const classes = useStyles();
  const [currentPayroll, setCurrentPayroll] = useState<IPayrollDto>(null);
  const [currentEmployee, setCurrentEmployee] = useState<IEmployeeBaseDto>(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [payrollDaySummary, setPayrollDaySummary] = useState<any>();
  const [currentMonth, setCurrentMonth] = useState<number>(dayjs(Date.now()).month() + 1);
  const [currentYear, setCurrentYear] = useState<number>(dayjs(Date.now()).year());
  const [filters, setFilters] = useState<IPayrollPeriodFiltersDto>({
    from: null,
    to: null,
  });

  useEffect(() => {
    const currentUser = getCurrentUser();
    if (!currentUser || !currentUser.id) return;

    setErrorMessage("");

    getEmployeeByUserId(currentUser.id)
      .then((employee) => {
        setCurrentEmployee(employee);
      })
      .catch((error: string) => {
        if (error) setErrorMessage(error);
      });
  }, []);

  useEffect(() => {
    if (!currentEmployee || !currentEmployee.id) return;

    getEmployeePayrollsForEmployee(currentEmployee.id, currentMonth, currentYear)
      .then((payroll) => {
        setCurrentPayroll(payroll);
      })
      .catch((error: string) => {
        if (error) setErrorMessage(error);
      });
  }, [currentEmployee, currentMonth, currentYear]);

  useEffect(() => {
    if (!currentEmployee || !currentEmployee.id) return;

    getPayrollPeriodSum(currentEmployee.id, filters)
      .then((payroll) => {
        setPayrollDaySummary(payroll);
      })
      .catch((error: string) => {
        if (error) setErrorMessage(error);
      });
  }, [currentEmployee, filters]);

  const handleMonthChange = (selectedMonth: number) => {
    let newYear = currentYear;
    let newMonth = selectedMonth;

    if (currentMonth === 1 && selectedMonth === 12) {
      newYear = currentYear - 1;
    }

    if (currentMonth === 12 && selectedMonth === 1) {
      newYear = currentYear + 1;
    }

    setCurrentMonth(newMonth);
    setCurrentYear(newYear);

    const fromDate = dayjs()
      .month(newMonth - 1)
      .year(newYear)
      .startOf("month")
      .toDate();
    const toDate = dayjs()
      .month(newMonth - 1)
      .year(newYear)
      .endOf("month")
      .toDate();

    setFilters({
      from: fromDate,
      to: toDate,
    });
  };

  const { mutate: exportExcel } = useMutation(() =>
    exportEmployeePayrollExcel(currentPayroll.id, currentPayroll.id)
  );

  if (!currentPayroll || !payrollDaySummary) return <Skeleton variant="rect" height="100%" />;

  return (
    <Grid container>
      <Grid item xs={12}>
        <PaycheckHeader />
      </Grid>

      <Grid item xs={12}>
        <PayrollSelection
          key={currentMonth}
          selectionData={[12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
          currentSelection={currentMonth}
          heading={dayjs()
            .month(currentMonth - 1)
            .format("MMMM")}
          earnings={payrollDaySummary.totalSum}
          ordersCount={payrollDaySummary.ordersCount}
          onSelectionChange={handleMonthChange}
        />
      </Grid>

      <Grid item xs={12}>
        <PayrollDaysSummary employeePayroll={currentPayroll} employeeId={currentEmployee.id} />
      </Grid>
    </Grid>
  );
};

export default Paycheck;