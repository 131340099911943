import {
  getScheduleForEmployeePerPeriod,
  insertEmployeeSchedule,
} from "../../../../../shared/services/schedule.service";
import FixedHeader from "./styles/FixedHeader";
import type { DriverWorkDay } from "./types";
import useStyles from "@App/Scheduler/utils/useStyles";
import DayjsUtils from "@date-io/dayjs";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  Box,
  Button,
  IconButton,
  Modal,
  Snackbar,
  SnackbarContent,
  Typography,
} from "@mui/material";
import { getCurrentUser } from "@shared/services/auth/auth.service";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

const ScheduleAsDriver: React.FC = () => {
  const currentUser = getCurrentUser();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
  const classes = useStyles();
  const user = getCurrentUser();
  const [workWeekState, setWorkWeekState] = useState<DriverWorkDay[]>(
    Array.from({ length: 28 }).map((_, i) => ({
      date: dayjs()
        .add(0 + i, "day")
        .format("YYYY-MM-DD"),
      isWorking: false,
      workTimes:
        i === 0
          ? [
              {
                startTime: "",
                endTime: "",
                id: i,
                reason: "",
              },
            ]
          : [{ startTime: "", endTime: "", id: i, reason: "" }],
      workPerDay: true,
      reason: "",
    }))
  );

  const {
    data: scheduledData,
    isLoading,
    isFetching,
    error,
    refetch,
  } = useQuery(
    ["deliveryTypes"],
    () =>
      getScheduleForEmployeePerPeriod(
        user.employeeId.toString(),
        dayjs().toString(),
        dayjs().add(28, "day").toString()
      ),
    {
      refetchInterval: 15000,
    }
  );
  useEffect(() => {
    if (scheduledData && !isLoading) {
      const displayedDates = new Set(workWeekState.map((day) => day.date));

      const filteredScheduledData = scheduledData.filter((schedule) =>
        displayedDates.has(dayjs(schedule.dateWork).format("YYYY-MM-DD"))
      );

      const updatedWorkWeekState = filteredScheduledData.map((schedule) => ({
        date: dayjs(schedule.dateWork).format("YYYY-MM-DD"),
        isWorking: schedule.isWorking,
        workTimes: [
          {
            startTime: schedule.startTime,
            endTime: schedule.endTime,
            id: schedule.id,
            reason: schedule.reason,
          },
        ],
        reason: schedule.reason,
        workPerDay: true,
      }));

      const mergedWorkWeekState = workWeekState.map((day) => {
        const update = updatedWorkWeekState.find((update) => update.date === day.date);
        return update ? { ...day, ...update } : day;
      });

      setWorkWeekState(mergedWorkWeekState);
    }
  }, [isLoading]);

  const handleSwitchToggle = (index: number) => {
    const newState = [...workWeekState];
    newState[index].isWorking = !newState[index].isWorking;
    setWorkWeekState(newState);
  };

  const handleWorkingHoursChange = (
    dayIndex: number,
    timeIndex: number,
    field: "startTime" | "endTime",
    value: string
  ) => {
    if (isSubmitted) {
      return;
    }

    const newState = [...workWeekState];
    newState[dayIndex].workTimes[timeIndex][field] = value;
    setWorkWeekState(newState);

    const day = newState[dayIndex];
    const allWorkingTimesFilled = day.workTimes.every((time) => time.startTime && time.endTime);

    if (allWorkingTimesFilled) {
      handleSwitchToggle(dayIndex);
    }
  };

  const submitWorkWeek = () => {
    const hasIncompleteTimeFields = workWeekState.some((day) =>
      day.workTimes.some((time) => {
        const startTimeEmpty = time.startTime === "";
        const endTimeEmpty = time.endTime === "";
        return (startTimeEmpty && !endTimeEmpty) || (!startTimeEmpty && endTimeEmpty);
      })
    );

    if (hasIncompleteTimeFields) {
      setShowErrorMessage(true);
    } else {
      insertEmployeeSchedule(currentUser.employeeId, workWeekState);
      setModalOpen(false);
      setIsSubmitted(true);
      setShowSuccessMessage(true);
    }
  };

  const handleSnackbarClose = () => {
    setShowSuccessMessage(false);
  };

  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);

  const handleErrorMessageClose = () => {
    setShowErrorMessage(false);
  };

  const timePickerStyle = {
    width: "calc(50% - 5px)",
    marginRight: 5,

    "& .MuiFormLabel-root": {
      opacity: 0.3,
    },
  };

  return (
    <MuiPickersUtilsProvider utils={DayjsUtils}>
      <FixedHeader />
      <div
        className={classes.parentDiv}
        style={{
          paddingBottom: 35,
          marginTop: 45,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          overflowY: "auto",
          maxHeight: "800vh",
        }}
      >
        {workWeekState.length !== 0 &&
          workWeekState.map((day, dayIndex) => (
            <Box
              key={day.date}
              m={1}
              display="flex"
              alignItems="center"
              style={{ borderBottom: "1px solid black" }}
            >
              <Typography
                style={{
                  color: day.isWorking ? "inherit" : "gray",
                  marginRight: 5,
                  display: "flex",
                  alignItems: "center",
                  fontWeight: day.isWorking? "bold" : "normal" 
                }}
              >
                <span>{dayjs(day.date).format("DD.MM.YYYY")}</span>
                <span style={{ marginLeft: "5px" }}>
                  -{dayjs(day.date).format("dd")[0].toUpperCase()}
                </span>
              </Typography>
              {/* maby add this later who knows :) */}
              {/* <Switch
              className={classes.toggle}
              color="default"
              checked={day.isWorking}
              onChange={() => handleSwitchToggle(dayIndex)}
              onClick={(event: React.MouseEvent) => event.stopPropagation()}
              disabled={isSubmitted}
            /> */}

              {day.workTimes.map((_, timeIndex) => (
                <Box
                  key={timeIndex}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  mt={timeIndex !== 0 ? 0 : -2}
                  paddingBottom="5px"
                  paddingRight="1px"
                  paddingLeft="30px"
                >
                  <TimePicker
                    label="Почеток"
                    ampm={false}
                    value={
                      day.workTimes[timeIndex].startTime
                        ? dayjs(day.workTimes[timeIndex].startTime, "HH:mm")
                        : null
                    }
                    onChange={(date) =>
                      handleWorkingHoursChange(
                        dayIndex,
                        timeIndex,
                        "startTime",
                        date?.format("HH:mm") || ""
                      )
                    }
                    minutesStep={15}
                    style={timePickerStyle}
                    disabled={
                      day.date === dayjs().format("YYYY-MM-DD") ||
                      day.date === dayjs().add(1, "day").format("YYYY-MM-DD") || 
                      day.date === dayjs().add(2, "day").format("YYYY-MM-DD") ||
                      day.date <= dayjs().add(7, "day").format("YYYY-MM-DD") && day.isWorking
                    }
                  />

                  <TimePicker
                    label="Крај"
                    ampm={false}
                    value={
                      day.workTimes[timeIndex].endTime
                        ? dayjs(day.workTimes[timeIndex].endTime, "HH:mm")
                        : null
                    }
                    onChange={(date) =>
                      handleWorkingHoursChange(
                        dayIndex,
                        timeIndex,
                        "endTime",
                        date?.format("HH:mm") || ""
                      )
                    }
                    minutesStep={15}
                    style={timePickerStyle}
                    disabled={
                      day.date === dayjs().format("YYYY-MM-DD") || // Disable if today
                      day.date === dayjs().add(1, "day").format("YYYY-MM-DD") || 
                      day.date === dayjs().add(2, "day").format("YYYY-MM-DD") ||
                      (day.date <= dayjs().add(7, "day").format("YYYY-MM-DD") && day.isWorking) // Disable if within next 7 days and working
                    }
                  />
                  {/* currently not needed */}
                  {/* {timeIndex !== 0 && (
                  <IconButton onClick={() => removeWorkingHours(dayIndex, timeIndex)}>
                    <DeleteOutlineIcon className={classes.deleteTimeItem} />
                  </IconButton>
                )}
                {timeIndex === 0 && (
                  <IconButton onClick={() => addWorkingHours(dayIndex)}>
                    <AddCircleOutlineIcon className={classes.addTimeItem} />
                  </IconButton>
                )} */}
                </Box>
              ))}
            </Box>
          ))}
      </div>

      <Box position="fixed" bottom={58} left={0} width="100%" padding="0 16px">
        <div
          style={{
            position: "absolute",
            left: 0,
            width: "100%",
            height: 40,
            background: "linear-gradient(to top, white, transparent",
            pointerEvents: "none",
          }}
        />
        <Button
          variant="contained"
          className={classes.primaryContained}
          onClick={() => setModalOpen(true)}
          style={{ color: "white", width: "100%" }}
        >
          Потврди
        </Button>
      </Box>

      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <div className={classes.modalContent}>
          <h3>Потврдување на распоред за работната недела!</h3>
          <Typography>
            Никакви промени не можат да бидат направени подоцна, освен ако не разговарате со некој
            од администрацијата.
          </Typography>
          <Typography>Дали сте сигурни дека сакате да продолжите?</Typography>
          <div className={classes.buttonDiv}>
            <Button
              variant="outlined"
              className={classes.errorOutlined}
              onClick={() => setModalOpen(false)}
            >
              Не
            </Button>
            <Button
              variant="contained"
              className={classes.primaryContained}
              onClick={submitWorkWeek}
              style={{ color: "white" }}
            >
              Да
            </Button>
          </div>
        </div>
      </Modal>
      <Snackbar open={showSuccessMessage} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <SnackbarContent
          style={{ backgroundColor: "#4CAF50", color: "white" }}
          message="Успешно го зачувавте вашиот распоред!"
        />
      </Snackbar>
      <Snackbar open={showErrorMessage} autoHideDuration={6000} onClose={handleErrorMessageClose}>
        <SnackbarContent
          style={{ backgroundColor: "#f44336", color: "white" }}
          message="Грешка: Внесете работно време за деновите кога е означено дека работите!"
        />
      </Snackbar>
    </MuiPickersUtilsProvider>
  );
};

export default ScheduleAsDriver;
